.modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.modal__body {
    display: block;
    width: 400px;
    background-color: var(--white);
    opacity: 1;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    border: solid 1px rgba(255, 255, 255, 0);
    margin-top: 84px;
}

.modal__body--full-screen {
    margin: 20px;
    padding: 0px;
    width: calc(100% - 40px);
    height: calc(100vh - 40px);
    overflow: auto;
}

.modal__body.modal__body--no-padding {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
}

.modal__body--w-600 {
    width: 600px;
}

.modal__body--w-800 {
    width: 800px;
}

.modal__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.33;
    color: var(--N900);
    margin-top: 0px;
    margin-bottom: 0px;
}

.modal__close-icon {
    display: inline;
    vertical-align: middle;
    .stroke-color {
        stroke: var(--N600);
        stroke-width: 2;
    }
}

.full-screen-modal__body-container {
    flex: 1;
    overflow: auto;
    height: 100%;
    background: white;
}

.close-btn {
    position: fixed;
    top: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 24px;
}

.close-img {
    color: var(--N400);
    font-size: 32px;
    width: 24px;
    height: 24px;
}

#full-screen-modal,
#visible-modal,
#visible-modal-2 {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    z-index: var(--modal-index);
    transition: all 200ms cubic-bezier(0.25, 0.5, 0.5, 0.9);
    max-height: 100vh;
    overflow: auto;
    &.no-back-drop {
        height: 0;
        width: 0;
    }
}

#full-screen-modal {
    &.show-with-bg {
        -webkit-backdrop-filter: blur(1px);
        backdrop-filter: blur(1px);
        background-color: var(--white);
        visibility: visible;
        opacity: 1;
    }

    &.show {
        background-color: var(--white);
        visibility: visible;
        opacity: 1;
    }
}

#visible-modal,
#visible-modal-2 {
    &.show-with-bg {
        -webkit-backdrop-filter: blur(1px);
        backdrop-filter: blur(1px);
        background-color: rgba(0, 6, 13, 0.8);
        visibility: visible;
        opacity: 1;
    }
}

#visible-modal {
    &.show {
        visibility: visible;
        opacity: 1;
    }
}

.visible-modal__body {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;

    &.notification-page-modal {
        display: block;
        background-color: var(--white);
        opacity: 1;
        margin: 50px auto;
        padding: 24px;
        border-radius: 8px;
        border: solid 1px rgba(255, 255, 255, 0);
        height: unset;
        width: fit-content;
        position: static;
        overflow: auto;
    }
}

.confirmation-dialog__body {
    margin: 0 auto;
    width: 450px;
    padding: 24px;
    background: white;
    border-radius: 8px;
    margin-top: 40px;
    position: relative;
}

.confirmation-dialog__body--w-400 {
    width: 400px;
}

.confirmation-dialog__icon {
    width: 48px;
    margin-bottom: 16px;
}

.confirmation-dialog__title {
    font-size: 16px;
    margin-top: 0;
    font-weight: 600;
    margin-bottom: 12px;
    color: var(--N900)
}

.confirmation-dialog__subtitle {
    font-size: 13px;
    color: var(--N700);
}

.confirmation-dialog__button-group {
    margin-top: 32px;
    button {
        margin-left: 16px;
    }
}

.full-screen-modal {
    width: 100%;
    height: 100%;
    z-index: 50;
    position: fixed;
    left: 0;
    top: 0;
}

.modal__body-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
}

.modal__body-bottom {
    border-top: solid 1px var(--N200);
}

.modal__main-img {
    margin-bottom: 16px;
    width: 48px;
    height: 48px;
}

.cta-cd-delete-modal {
    min-width: 76px !important;
}

.drawer--container {
    display: flex;
    overflow: hidden;
}

.modal__body--p-0 {
    padding: 0px;
}
